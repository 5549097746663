/**
 * Custom 404 page.
 *
 * When developing using gatsby develop, Gatsby uses a default 404 page that overrides your custom 404 page.
 * However, you can still preview your 404 page by clicking “Preview custom 404 page” to verify that it’s working as expected.
 * This is useful when you’re developing so that you can see all the available pages.
 */

/**
 * External dependency.
 */
import React, { useEffect } from 'react';
import { navigate } from "gatsby"


/**
 * Internal dependencies.
 */
import Layout from '../components/layout';
import NotFound from '../components/404';
import data from '../components/404/stories/data';
import { langSlugs } from '../utils/lang-slugs';


const NotFoundPage = (props) => {

	useEffect( () => {
		const refUrl = props.location;
		const requestedUrl = new URL( refUrl.href );
		const requestedUrlPath = requestedUrl.pathname.split( '/', 2 );

		if ( -1 !== langSlugs.indexOf( requestedUrlPath[1] ) ) {
			const newPath = refUrl.pathname.replace( `${requestedUrlPath[1]}/`, '' );
			navigate( newPath );
		}

		return () => null;

	}, [] ); // eslint-disable-line

	return (
		<Layout title="404 Page Not Found">
			<NotFound data={ data } />
		</Layout>
	)
}

export default NotFoundPage
