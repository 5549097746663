import desktopImage from './../../../images/stories/desktop-404@2x.png';
import mobileImage from './../../../images/stories/mobile-404@2x.png';

export default {
	title: 'Uh oh, haven\'t found what you’re looking for?',
	content: 'It’s easy to get lost on the open web.',
	desktopImage: {
		altText: 'Page Not Found',
		sourceUrl: desktopImage,
		srcSet: '',
		sizes: '',
		mediaDetails: {
			width: 458,
			height: 649,
		}
	},
	mobileImage: {
		altText: 'Page Not Found',
		sourceUrl: mobileImage,
		srcSet: '',
		sizes: '',
		mediaDetails: {
			width: 319,
			height: 227
		}
	},
	links: [
		{
			label: 'Visit our blog',
			uri: '/blog/'
		},
		{
			label: 'Read about our technology',
			uri: '/technology/'
		},
	],
}
