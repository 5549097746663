export const langSlugs = [
	'af',
	'sq',
	'ar',
	'eu',
	'bg',
	'be',
	'ca',
	'zh',
	'hr',
	'cs',
	'da',
	'nl',
	'en',
	'et',
	'fo',
	'fa',
	'fi',
	'fr',
	'gd',
	'de',
	'el',
	'he',
	'hi',
	'hu',
	'is',
	'id',
	'it',
	'ja',
	'ko',
	'lv',
	'lt',
	'mk',
	'mt',
	'no',
	'pl',
	'pt',
	'rm',
	'ro',
	'ru',
	'sz',
	'sr',
	'sk',
	'sl',
	'sb',
	'es',
	'sx',
	'sv',
	'th',
	'ts',
	'tn',
	'tr',
	'uk',
	'ur',
	've',
	'vi',
	'xh',
	'ji',
	'zu',
];
