/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import LinkWithArrow from '../link-with-arrow';
import Image from '../image/index';

import './style.scss';

/**
 * NotFound Component.
 *
 * @param {Object} data Component props.
 *
 * @return {jsx}
 */
const NotFound = ({ data }) => {

	const { mobileImage, desktopImage, title, content, links } = data;

	return (
		<article className="not-found">
			<div className="row">
				<div className="medium-7 small-12 columns">
					<div className="not-found__grid">
						<h1 className="not-found__title">{title}</h1>
						<div className="not-found__content">{content}</div>

						<figure className="not-found__figure show-for-small-only">
							<Image {...mobileImage} className="not-found__image" />
						</figure>

						<ul className="not-found__links">
							{links.map(link => (
								link.uri ? (
									<li className="not-found__link-item" key={link.label}>
										<LinkWithArrow text={link.label} link={link.uri} color="#000" />
									</li>
								) : ''
							))}
						</ul>
					</div>
				</div>
				<div className="medium-5 small-12 columns">
					<figure className="not-found__figure hide-for-small-only">
						<Image {...desktopImage} className="not-found__image" />
					</figure>
				</div>
			</div>
		</article>
	);
};

NotFound.propTypes = {
	data: PropTypes.object,
};

NotFound.defaultProps = {
	data: {
		title: '',
		content: '',
		desktopImage: {
			altText: '',
			sourceUrl: '',
			title: '',
			srcSet: '',
			sizes: '',
			mediaDetails: {
				width: null,
				height: null
			}
		},
		mobileImage: {
			altText: '',
			sourceUrl: '',
			title: '',
			srcSet: '',
			sizes: '',
			mediaDetails: {
				width: null,
				height: null
			}
		},
		links: [
			{
				label: '',
				url: ''
			},
		],
	}
};

export default NotFound;
